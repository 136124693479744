<template>
  <div>
    <div
      class="block-icon-reglement-fac mr-1"
      @click.prevent.stop="sequencesFacturesFiliale(item)"
      title="Séquences Factures"
    >
      <font-awesome-icon icon="list" />
    </div>
    <!--GESTION DES SEQUENCES-->
    <v-dialog
      v-model="handleModalSequence"
      persistent
      max-width="1400px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-list"
      :retain-focus="false"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >GESTION DES SÉQUENCES FACTURES
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalSeq('sequence', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div v-if="getSequenceLoader" class="loading-block">
            <v-progress-circular
              class="ml-2"
              v-if="getSequenceLoader"
              indeterminate
              color="#704ad1"
            ></v-progress-circular>
          </div>
          <div v-else class="form-add">
            <v-row>
              <v-col cols="2">
                <span class="titleFields form-add">Champs dynamiques</span>
              </v-col>
              <v-col cols="1">
                <span class="titleFields form-add" v-if="copierText"
                  >Text copier</span
                >
              </v-col>
              <v-col cols="4">
                <div
                  v-for="(prop, val) of getSequencesFacturesFields"
                  :key="prop.id"
                  @click="CopyFields({ prop: prop, val: val })"
                  class="filed-class"
                  title="Copier"
                >
                  <div class="icon-field">☰</div>
                  <div class="value-field">
                    {{ val }}
                  </div>
                  <div class="icon-copy">
                    <font-awesome-icon icon="copy" />
                  </div>
                </div>
              </v-col>
              <v-col cols="3"></v-col>
              <v-col cols="2">
                <div
                  class="block-icon-table-facture fix-width mr-1"
                  @click.prevent.stop="handleClickAddExercice()"
                  title="Ajouter un exercice"
                >
                  <font-awesome-icon icon="plus" /> Ajouter un exercice
                </div>
              </v-col>
            </v-row>
            <v-row
              v-for="(sequence, index) in getSequencesOfFiliale"
              :key="index"
              class="card-style"
            >
              <v-row class="ml-2 mb-2">
                <v-col cols="2">
                  <b>Exercice {{ sequence.exercice }} </b>
                </v-col>
                <v-col cols="2">
                  <v-menu :close-on-content-click="true" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="sequence.begin_date"
                        :value="sequence.begin_date"
                        :disabled="
                          sequence.sequences[0].last_num != '0' ||
                            sequence.sequences[1].last_num != '0' ||
                            sequence.sequences[2].last_num != '0' ||
                            sequence.sequences[3].last_num != '0'
                        "
                        label="Date de début *"
                        outlined
                        readonly
                        hide-details
                        v-on="on"
                        required
                        :persistent-placeholder="true"
                        @change="changeDateSeq(index, 'begin_date')"
                        placeholder="Sélectionner un date"
                        prepend-inner-icon="mdi-calendar"
                        :autofocus="false"
                        color="#704ad1"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#704ad1"
                      header-color="#704ad1"
                      event-color="704ad1"
                      v-model="sequence.begin_date"
                      :value="sequence.begin_date"
                      @change="changeDateSeq(index, 'begin_date')"
                      locale="fr"
                      :disabled="
                        sequence.sequences[0].last_num != '0' ||
                          sequence.sequences[1].last_num != '0' ||
                          sequence.sequences[2].last_num != '0' ||
                          sequence.sequences[3].last_num != '0'
                      "
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2">
                  <v-menu :close-on-content-click="true" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="sequence.end_date"
                        v-model="sequence.end_date"
                        :disabled="
                          (sequence.sequences[0].last_num != '0' ||
                            sequence.sequences[1].last_num != '0' ||
                            sequence.sequences[2].last_num != '0' ||
                            sequence.sequences[3].last_num != '0') &&
                            index != 0
                        "
                        @change="changeDateSeq(index, 'end_date')"
                        label="Date de fin *"
                        outlined
                        readonly
                        hide-details
                        v-on="on"
                        :persistent-placeholder="true"
                        placeholder="Sélectionner un date"
                        prepend-inner-icon="mdi-calendar"
                        :autofocus="false"
                        color="#704ad1"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#704ad1"
                      header-color="#704ad1"
                      event-color="704ad1"
                      v-model="sequence.end_date"
                      :value="sequence.end_date"
                      @change="changeDateSeq(index, 'end_date')"
                      :min="
                        sequence.sequences[0].last_num != '0' ||
                        sequence.sequences[1].last_num != '0' ||
                        sequence.sequences[2].last_num != '0' ||
                        sequence.sequences[3].last_num != '0'
                          ? dateLimit
                          : undefined
                      "
                      locale="fr"
                      :disabled="
                        (sequence.sequences[0].last_num != '0' ||
                          sequence.sequences[1].last_num != '0' ||
                          sequence.sequences[2].last_num != '0' ||
                          sequence.sequences[3].last_num != '0') &&
                          index != 0
                      "
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <div
                    class="warning-week warning-block mt-2"
                    v-if="
                      sequence.sequences[0].last_num != '0' ||
                        sequence.sequences[1].last_num != '0' ||
                        sequence.sequences[2].last_num != '0' ||
                        sequence.sequences[3].last_num != '0'
                    "
                  >
                    <font-awesome-icon
                      class="info-icon mr-1"
                      icon="info-circle"
                    />
                    Date début et Date fin modifiable si le "Dernier numéro"
                    dans tous les séquences égale à zéro
                  </div>
                </v-col>
              </v-row>
              <v-row
                class="mb-1"
                v-for="(seq, index2) in sequence.sequences"
                :key="seq + index2"
                style="margin: 0"
              >
                <v-col cols="2">
                  <v-text-field
                    :label="seq.title + ' *'"
                    :persistent-placeholder="true"
                    v-model="seq.sequence"
                    :disabled="seq.last_num != '0'"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Longeur *"
                    :persistent-placeholder="true"
                    v-model="seq.num_length"
                    :disabled="seq.last_num != '0'"
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="2">
                  <v-text-field
                    label="Partir de *"
                    :persistent-placeholder="true"
                    v-model="seq.begin_from"
                    outlined
                    :disabled="seq.last_num != '0'"
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Dernier numéro"
                    :persistent-placeholder="true"
                    v-model="seq.last_num"
                    outlined
                    color="#704ad1"
                    disabled
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-menu :close-on-content-click="true" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="seq.begin_date"
                        v-model="seq.begin_date"
                        :disabled="true"
                        label="Date de début *"
                        outlined
                        readonly
                        hide-details
                        v-on="on"
                        required
                        :persistent-placeholder="true"
                        placeholder="Sélectionner un date"
                        prepend-inner-icon="mdi-calendar"
                        :autofocus="false"
                        color="#704ad1"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#704ad1"
                      header-color="#704ad1"
                      event-color="704ad1"
                      :value="seq.begin_date"
                      v-model="seq.begin_date"
                      :disabled="true"
                      format="YYYY-MM-DD"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2">
                  <v-menu :close-on-content-click="true" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="seq.end_date"
                        v-model="seq.end_date"
                        :disabled="true"
                        label="Date de début *"
                        outlined
                        readonly
                        hide-details
                        v-on="on"
                        required
                        :persistent-placeholder="true"
                        placeholder="Sélectionner un date"
                        prepend-inner-icon="mdi-calendar"
                        :autofocus="false"
                        color="#704ad1"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      format="YYYY-MM-DD"
                      color="#704ad1"
                      header-color="#704ad1"
                      event-color="704ad1"
                      :value="seq.end_date"
                      v-model="seq.end_date"
                      :disabled="true"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <div class="validate">
                <v-btn
                  color="#704ad1"
                  type="submit"
                  @click.prevent.stop="
                    handleAddSequance(filialeToUpdate, sequence, index)
                  "
                  :loading="sequence.loader"
                  :disabled="sequence.loader"
                  :class="{ loader: sequence.loader }"
                >
                  Valider
                </v-btn>
              </div>
            </v-row>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="getSequenceErrors" class="message-error-modal">
            <ul v-if="Array.isArray(getSequenceErrors)" class="mb-0">
              <li v-for="(e, index) in getSequenceErrors" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>
              {{ getSequenceErrors }}
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="handleModalExercice"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter un exercice </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalSeq('exercice', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="12">
                <v-select
                  placeholder="Séléctionnez"
                  :items="computedGetListExerciceForSociete"
                  class="select-menu"
                  label="Année"
                  :persistent-placeholder="true"
                  v-model="exercice"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  :disabled="getLoaderListExerciceForSociete"
                >
                </v-select>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <div v-if="errorExercice" class="message-error-modal">
          <ul v-if="Array.isArray(errorExercice)" class="mb-0">
            <li v-for="(e, index) in errorExercice" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal" v-else>{{ errorExercice }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleSubmitExercice"
            :loading="getLoaderListExerciceForSociete"
            :disabled="getLoaderListExerciceForSociete"
            :class="{ loader: getLoaderListExerciceForSociete }"
          >
            Ajouter
          </v-btn>
          <v-btn text @click="handleModalSeq('exercice', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'sequences',
  props: {
    item: { required: true }
  },
  data() {
    return {
      filialeToUpdate: null,
      handleModalSequence: false,
      handleModalExercice: false,
      isLoading: false,
      copierText: false,
      exercice: null,
      errorExercice: null,
      dateLimit: moment(new Date()).format('YYYY-MM-DD')
    };
  },
  computed: {
    ...mapGetters([
      'getSequenceLoader',
      'getSequenceErrors',
      'getSequencesFacturesFields',
      'getSequencesOfFiliale',
      'getLoaderListExerciceForSociete',
      'getListExerciceForSociete'
    ]),
    formattedBeginDate() {
      return this.sequence.begin_date
        ? this.formatDate(this.sequence.begin_date)
        : null;
    },
    computedGetListExerciceForSociete() {
      let table = [...Array(2040 - 2015 + 1).keys()].map(x => x + 2015);
      return table.map(item => {
        if (this.getListExerciceForSociete.includes(item)) {
          return { text: item, disabled: true };
        } else {
          return { text: item, disabled: false };
        }
      });
    }
  },
  methods: {
    ...mapActions([
      'getAllSequancesOfFiliale',
      'fetchExerciceForSociete',
      'addSequanceForSociete',
      'addExerciceFiliale'
    ]),
    changeDateSeq(index, type) {
      this.getSequencesOfFiliale[index].sequences.map(item => {
        item[type] = this.getSequencesOfFiliale[index][type];
      });
    },
    async handleSubmitExercice() {
      this.errorExercice = null;
      const response = await this.addExerciceFiliale({
        id: this.filialeToUpdate.id,
        exercice: this.exercice
      });
      if (response.succes) {
        this.handleModalSeq('exercice', 'hide');
      } else {
        this.errorExercice = response.error;
      }
    },
    handleClickAddExercice() {
      this.fetchExerciceForSociete(this.filialeToUpdate.id);
      this.handleModalSeq('exercice', 'show');
    },
    async handleAddSequance(filiale, exercice, index) {
      this.getSequencesOfFiliale[index].loader = true;
      let objectSeq = {
        id: filiale.id,
        sequences_factures: exercice
      };
      await this.addSequanceForSociete(objectSeq);
      this.getSequencesOfFiliale[index].loader = false;
    },
    async sequencesFacturesFiliale(filiale) {
      this.filialeToUpdate = { ...filiale };
      this.handleModalSeq('sequence', 'show');
      const response = await this.getAllSequancesOfFiliale(filiale.id);
    },
    CopyFields(payload) {
      var copyTextarea = document.createElement('textarea');
      copyTextarea.style.position = 'fixed';
      copyTextarea.style.opacity = '0';
      copyTextarea.textContent = payload.prop;
      copyTextarea.value = payload.prop;
      document.body.appendChild(copyTextarea);
      copyTextarea.select();
      navigator.clipboard.writeText(payload.prop);
      document.body.removeChild(copyTextarea);
      this.copierText = true;
      setTimeout(() => {
        this.copierText = false;
      }, 800);
    },
    handleModalSeq(method, action) {
      if (method == 'sequence') {
        if (action == 'show') {
          this.handleModalSequence = true;
        } else {
          this.handleModalSequence = false;
          this.filialeToUpdate = null;
          this.isLoading = false;
        }
      }
      if (method == 'exercice') {
        if (action == 'show') {
          this.handleModalExercice = true;
        } else {
          this.handleModalExercice = false;
          this.errorExercice = null;
          this.exercice = null;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.loading-block {
  margin-left: 50%;
  margin-top: 1%;
}
.validate {
  margin-left: 47%;
}
button.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
  color: #fff;
}
.card-style {
  border-radius: 10px;
  border: 1px solid #b5b5b5;
  padding: 28px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.titleFields {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #2a2a2a;
}

.filed-class {
  border: 1px solid #6c757d4d;
  background-color: #ebebeb5c;
  border-radius: 4px;
  position: relative;
  padding: 5px;
  width: 211px;
  margin: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #2a2a2a;
  .icon-field {
    position: absolute;
    left: 6px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .value-field {
    display: flex;
    justify-content: space-between;
    margin-left: 25px;
  }
  .icon-copy {
    position: absolute;
    right: 6px;
    width: 25px;
    top: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.fix-width {
  width: 128px !important;
  padding-bottom: 23px;
  margin-left: 42%;
  padding-left: 8px;
  margin-top: 5%;
}
</style>
